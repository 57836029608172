<template>
  <div>
    <b-alert
      v-if="dataLoading"
      show
      variant="info"
    >
      <div class="alert-body pt-3">
        <loading />
      </div>
    </b-alert>
    <template v-else>
      <sale-header />
      <customer />
      <new-car />
      <swap-car v-if="itemData.id_com_swapcar" />
      <template v-if="['0','1','2','3'].includes(itemData.status)">
        <payments />
      </template>
      <support-invoices v-if="(userData.id_com_department === '21' || userData.id_com_user_type === '1') && itemData.supportInvoices" />
      <template v-if="Number(itemData.id_com_swapcar) > 0">
        <swap-car-sale-contract />
      </template>
      <template v-if="itemData.status === '1'">
        <invoice-result />
        <buttons />
      </template>
      <template v-else-if="itemData.status === '0'">
        <template v-if="userData.id === itemData.id_com_user_manager">
          <invoice-calculator />
          <div class="text-center">
            <b-button
              variant="success"
              class="mr-1"
              @click="saveData(1)"
            >
              <FeatherIcon icon="CheckIcon" /> Onayla
            </b-button>
            <b-button
              variant="warning"
              class="ml-1"
              @click="saveData(3)"
            >
              <FeatherIcon icon="EditIcon" /> Revize İçin Gönder
            </b-button>
            <b-button
              variant="danger"
              class="ml-1"
              @click="saveData(2)"
            >
              <FeatherIcon icon="XIcon" /> Reddet
            </b-button>
          </div>
        </template>
      </template>
      <template v-else-if="itemData.status === '3'">
        <div
          class="text-center d-flex justify-content-between"
        >
          <b-button
            variant="primary"
            :to="'/sale_orderforms/add/' + itemData.order_number"
          >
            <FeatherIcon icon="EditIcon" /> Güncelle
          </b-button>
          <b-button
            v-if="itemData.status === '3' && userData.id === itemData.id_com_user"
            variant="danger"
            @click="removeItemData()"
          >
            <feather-icon icon="XIcon" />
            Föyü İptal Et
          </b-button>
          <b-button
            variant="success"
            @click="submitManager"
          >
            <FeatherIcon icon="SendIcon" /> Yönetici Onayına Gönder
          </b-button>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import {
  BButton, BAlert, BRow, BCol,
} from 'bootstrap-vue'
import SaleHeader from '@/views/Sales/Sale_orderforms/View/Header.vue'
import Customer from '@/views/Sales/Sale_orderforms/View/Customer.vue'
import NewCar from '@/views/Sales/Sale_orderforms/View/NewCar.vue'
import SwapCar from '@/views/Sales/Sale_orderforms/View/SwapCar.vue'
import Payments from '@/views/Sales/Sale_orderforms/View/Payments.vue'
import InvoiceCalculator from '@/views/Sales/Sale_orderforms/View/InvoiceCalculator.vue'
import InvoiceResult from '@/views/Sales/Sale_orderforms/View/InvoiceResult.vue'
import Buttons from '@/views/Sales/Sale_orderforms/View/Buttons.vue'
import Loading from '@/layouts/components/Loading.vue'
import SwapCarSaleContract from '@/views/Sales/Sale_orderforms/View/SwapcarSaleContract.vue'
import SupportInvoices from '@/views/Sales/Sale_orderforms/View/SupportInvoices.vue'

export default {
  name: 'SaleOrderFormView',
  components: {
    BButton,
    BAlert,
    BRow,
    BCol,
    Loading,
    SaleHeader,
    Customer,
    NewCar,
    SwapCar,
    Payments,
    InvoiceCalculator,
    InvoiceResult,
    Buttons,
    SwapCarSaleContract,
    SupportInvoices,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      dataLoading: true,
    }
  },
  computed: {
    itemData() {
      return this.$store.getters['saleOrderforms/getSaleOrderForm']
    },
    saveStatus() {
      return this.$store.getters['saleOrderforms/getSaleOrderFormSaveStatus']
    },
  },
  watch: {
    saveStatus(val) {
      if (val.status === true) {
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.submitStatus = false
      this.interview.submitStatus = false
    },
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.$store.dispatch('saleOrderforms/saleOrderFormsView', this.$route.params.order_number)
        .then(res => {
          if (res) {
            if (res.id_com_swapcar) {
              this.swapCar(res.id_com_swapcar)
            }
            if (res.id) {
              this.dataLoading = false
            }
          }
        })
    },
    swapCar(id) {
      this.$store.dispatch('swapcars/swapcarView', id)
    },
    submitManager() {
      if (Number(this.itemData.id_com_swapcar) > 0) {
        if (this.itemData.swapcarSaleContract) {
          this.$store.dispatch('saleOrderforms/submitManager', this.itemData.id)
        } else {
          this.$swal({
            icon: 'error',
            title: 'Föy Yönetici Onayına Gönderilemedi',
            text: 'Satış föyünde takas araç bulunmaktadır. Noter satış sözleşmesi yüklenmelidir.',
            confirmButtonText: this.$store.state.app.removeResultClose,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      } else {
        this.$store.dispatch('saleOrderforms/submitManager', this.itemData.id)
      }
    },
    saveData(status) {
      this.itemData.status = status
      this.$store.dispatch('saleOrderforms/managerSave', this.itemData)
    },
    removeItemData() {
      this.$swal({
        title: 'Föyü iptal etmek istiyor musunuz ?',
        text: 'Föy iptal edildiğinde bağlı olan araç bağlantısı kaldırılacak ve föye erişemeyeceksiniz.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$store.state.app.removeConfirm,
        cancelButtonText: this.$store.state.app.removeCancel,
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('saleOrderforms/saleOrderFormsCancel', {
            order_number: this.itemData.order_number,
            id: this.itemData.id,
            status: this.itemData.status,
          })
            .then(res => {
              console.log(res)
              if (res) {
                this.$swal({
                  icon: 'success',
                  title: 'Föy İptal Edildi.',
                  text: 'Föy iptal edilip araç kartı bağlantısı kaldırıldı.',
                  confirmButtonText: this.$store.state.app.removeResultClose,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
                this.$router.push({ name: 'SaleOrderForms' })
              }
            })
        }
      })
    },
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        allowOutsideClick: false,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      }).then(resoponse => {
        if (resoponse.isConfirmed) {
          this.getData()
          // this.$router.push(`/interviews/view/${this.interview.interview_number}`)
        }
      })
    },
  },
}
</script>
